import {
  BRow,
  BCol,
  BCard,
  BDropdownItem,
  BButton,
  BFormCheckbox,
  BTableSimple,
  BThead,
  BTbody,
  BTh,
  BTr,
  BTd,
  BImg,
  BBadge,
  BSkeletonTable,
  BModal,
  BFormInput,
} from 'bootstrap-vue';

import SidebarSearch from '@/components/SidebarSearch.vue';
import TableHeader from '@/components/TableHeader.vue';
import EmptyTableRow from '@/components/EmptyTableRow.vue';
import axios from '@axios';

const orderMixins = {
  components: {
    BRow,
    BCol,
    BCard,
    BDropdownItem,
    BButton,
    BFormCheckbox,
    BTableSimple,
    BThead,
    BTbody,
    BTh,
    BTr,
    BTd,
    BImg,
    BBadge,
    BSkeletonTable,
    BModal,
    BFormInput,
    SidebarSearch,
    TableHeader,
    EmptyTableRow,
  },
  metaInfo() {
    return {
      title: 'Kanal Dagang',
      titleTemplate: `%s | ${this.$route.meta.title}`,
    };
  },
  data() {
    return {
      orderStatus: 'NEW',
      tableData: [],
      currentPage: 1,
      perPage: 10,
      isLoading: true,
      showLoading: 0,
      totalRows: 0,
      isFetching: false,
      selectedDatas: [],
      selectAll: false,
      search: '',
      searchComponent: [
        {
          name: 'search',
          placeholder: 'Cari produk (nama, sku)',
          type: 'text',
          value: '',
        },
        {
          name: 'sortBy',
          label: 'Urutkan berdasarkan',
          placeholder: 'Urutkan berdasarkan',
          type: 'select',
          value: null,
          options: [
            { text: 'Terbaru', value: 'createdDate' },
            { text: 'Terlama', value: '-createdDate' },
          ],
        },
        {
          name: 'shop',
          label: 'Toko Anda',
          type: 'select-multiple',
          url: 'marketplace',
          optionKey: 'id',
          optionText: 'shopName',
          isMarketplace: true,
          key: 'data',
        },
        {
          name: 'courier',
          label: 'Kurir',
          type: 'select-multiple',
          options: [
            { title: 'JNE', value: 'jne' },
            { title: 'J&T', value: 'jnt' },
            { title: 'POS Indonesia', value: 'pos' },
            { title: 'SiCepat', value: 'sicepat' },
            { title: 'Tiki', value: 'tiki' },
          ],
        },
      ],
    };
  },
  computed: {
    formattedCurrency() {
      return price =>
        new Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency: 'IDR',
        }).format(price);
    },
    formattedNumber() {
      return stock => new Intl.NumberFormat('id-ID').format(stock);
    },
    formatDateWithTime() {
      return date => {
        const options = {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        };

        return new Date(date).toLocaleDateString('id-ID', options);
      };
    },
    timeAgo() {
      return dateString => {
        const date = typeof dateParam === 'object' ? dateString : new Date(dateString);
        const now = new Date();
        const seconds = Math.round((now - date) / 1000);
        const minutes = Math.round(seconds / 60);
        const hours = Math.round(minutes / 60);
        const days = Math.round(hours / 24);
        const months = Math.round(days / 30);
        const years = Math.round(days / 365);

        switch (true) {
          case seconds < 60:
            return 'baru saja';
          case minutes < 60:
            return `${minutes} menit yang lalu`;
          case hours < 24:
            return `${hours} jam yang lalu`;
          case days < 30:
            return `${days} hari yang lalu`;
          case months < 12:
            return `${months} bulan yang lalu`;
          default:
            return `${years} tahun yang lalu`;
        }
      };
    },
    marketplaceAbbreviation() {
      return marketplace => {
        if (marketplace === 'tokopedia') {
          return 'TKD';
        }
        if (marketplace === 'shopee') {
          return 'SHP';
        }
        if (marketplace === 'lazada') {
          return 'LZD';
        }

        return 'BLB';
      };
    },
    getFirstCharOfWords() {
      // example: "Toko Dummy" => "TD", "Toko" => "TK"
      return words => {
        // if words is undefined, null, or empty string return PR
        if (!words) {
          return 'DM';
        }
        const wordsArray = words.split(' ');
        const firstChar = wordsArray.map(word => word.charAt(0)).join('');

        return firstChar.toUpperCase();
      };
    },
  },
  async created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  async mounted() {
    await this.fetchData();
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      const isAtBottom =
        document.documentElement.scrollHeight -
          (window.innerHeight + window.scrollY) <=
        1;

      if (isAtBottom && !this.isFetching) {
        this.isFetching = true;

        this.fetchData();

        setTimeout(() => {
          this.isFetching = false;
        }, 1800);
      }
    },

    selectAllRows() {
      if (this.selectAll) {
        this.selectedDatas = this.tableData;
      } else {
        this.selectedDatas = [];
      }
    },
    async searchData(search = null) {
      this.showLoading = 1;
      this.search = search || this.search;
      this.tableData = [];
      this.currentPage = 1;
      this.totalRows = 0;
      await this.fetchData();
      this.showLoading = 0;
    },
    async fetchData() {
      if (this.tableData.length > 1) {
        if (this.tableData.length >= this.totalRows) {
          return;
        }
      }

      const params = {
        page: this.currentPage,
        perPage: this.perPage,
        status: this.orderStatus,
        showloading: this.showLoading,
      };

      if (this.search) {
        Object.assign(params, this.search);

        // if one key has array and has object like title and value, get the value and join it with comma
        Object.keys(params).forEach(key => {
          if (Array.isArray(params[key])) {
            params[key] = params[key].map(item => item.value).join(',');
          }
        });
      }

      const url = Object.keys(params)
        .map(key => `${key}=${params[key]}`)
        .join('&');

      const response = await axios.get(`order?${url}`);

      if (
        response &&
        response.data &&
        response.data.data &&
        response.data.data &&
        response.data.data.length > 0
      ) {
        this.tableData = [...this.tableData, ...response.data.data];
      }

      this.totalRows = response.data.total;
      this.currentPage += 1;
      this.isLoading = false;
    },
  },
};

export default orderMixins;
