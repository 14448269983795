var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"no-body":""}},[_c('SidebarSearch',{attrs:{"components":_vm.searchComponent},on:{"search":_vm.searchData}}),_c('TableHeader',{attrs:{"selected-length":_vm.selectedDatas.length,"per-page":_vm.perPage,"has-create-button":false,"disable-delete":true,"disable-filter":(_vm.isLoading || _vm.isFetching) && _vm.tableData.length === 0},on:{"per-page-change":function($event){_vm.perPage = $event}},scopedSlots:_vm._u([{key:"dropdown-items",fn:function(){return [_c('b-dropdown-item',{attrs:{"disabled":_vm.selectedDatas.length === 0}},[_c('Feather-icon',{staticClass:"text-success mr-1",attrs:{"icon":"CheckIcon"}}),_vm._v(" Terima Pesanan ("+_vm._s(_vm.selectedDatas.length)+") ")],1)]},proxy:true}])}),(_vm.isLoading)?_c('b-skeleton-table',{attrs:{"rows":4,"columns":7,"table-props":{ borderless: true, striped: true, small: true }}}):_c('b-table-simple',{staticStyle:{"zoom":"83%"},attrs:{"responsive":"","striped":"","small":""}},[_c('b-thead',[_c('b-tr',[_c('b-th',{staticClass:"bg-transparent",staticStyle:{"width":"6%"}},[_c('b-form-checkbox',{attrs:{"size":"md"},on:{"change":_vm.selectAllRows},model:{value:(_vm.selectAll),callback:function ($$v) {_vm.selectAll=$$v},expression:"selectAll"}})],1),_vm._l((_vm.tableColumns),function(column,index){return _c('b-th',{key:index,class:[column.class],style:({ width: column.style })},[_vm._v(" "+_vm._s(column.label)+" ")])})],2)],1),(_vm.tableData.length > 0)?_c('b-tbody',_vm._l((_vm.tableData),function(data,index){return _c('b-tr',{key:index,style:(data.productList.length < 1
            ? 'opacity: 0.5; pointer-events: none; user-select: none; border: 2px dashed red; background-color: #f8d7da; cursor: not-allowed;'
            : '')},[_c('b-td',[_c('b-form-checkbox',{attrs:{"value":data,"size":"md"},model:{value:(_vm.selectedDatas),callback:function ($$v) {_vm.selectedDatas=$$v},expression:"selectedDatas"}})],1),_c('b-td',[_c('b-img',{staticStyle:{"width":"24px","height":"24px"},attrs:{"src":require('@/assets/images/marketplace/' +
                data.marketplaceType +
                '.png'),"alt":data.marketplaceName}}),_vm._v(" "+_vm._s(data.marketplaceName ? data.marketplaceName : 'Toko Dummy')+" ")],1),_c('b-td',[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"my-1"},[_c('b-badge',{staticClass:"mr-1",attrs:{"variant":"primary"}},[_vm._v(" "+_vm._s(_vm.marketplaceAbbreviation(data.marketplaceType) + '-' + (data.orderId ? data.orderId : '-'))+" ")]),_c('b-badge',{staticClass:"mr-1"},[_vm._v(" "+_vm._s(_vm.formatDateWithTime(data.createdDate))+" ")])],1),_c('div',[_c('div',{staticClass:"d-flex flex-column"},[_c('b',[_vm._v(" List Produk :")]),_c('b-table-simple',{attrs:{"borderless":""}},[_c('b-tbody',_vm._l((data.productList),function(product,productIndex){return _c('b-tr',{key:productIndex},[_c('b-td',[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-img',{staticClass:"mr-2",attrs:{"width":"60","src":product.image
                                  ? product.image
                                  : 'https://cdn.statically.io/avatar/' +
                                    _vm.getFirstCharOfWords(product.productName),"alt":'product #' + productIndex,"thumbnail":""}}),_c('div',{staticClass:"flex-grow-1"},[_c('h6',{staticClass:"text-primary"},[_c('b',[_vm._v(_vm._s(product.productName))])]),_c('div',{staticClass:"d-flex flex-column mb-1"},[_c('small',[_c('b',[_vm._v(" ID Produk :")]),_vm._v(" "+_vm._s(product.productId ? product.productId : '-')+" ")]),_c('small',[_c('b',[_vm._v("SKU :")]),_vm._v(" "+_vm._s(product.sku ? product.sku : '-')+" ")])])])],1)])])],1)}),1)],1),_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"line-dashed"}),_c('p',{staticClass:"mt-1 mb-0 p-0"},[_vm._v(" Nama Penerima : "+_vm._s(data.recipientName ? data.recipientName : '-')+" ")]),_c('p',{staticClass:"m-0 p-0"},[_vm._v(" Nomor Resi : "+_vm._s(data.trackingNumber ? data.trackingNumber : '-')+" ")]),_c('p',{staticClass:"m-0 p-0"},[_vm._v(" Kurir : "+_vm._s(data.courierName)+" ")])])],1)])])]),_c('b-td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.formattedCurrency(data.totalPrice))+" ")]),_c('b-td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.formattedNumber(data.quantity)))]),_c('b-td',{staticClass:"text-center"},[(
              data.marketplaceType === 'tokopedia' ||
              data.marketplaceType === 'lazada'
            )?_c('b-button',{staticClass:"d-flex justify-content-between align-items-center",attrs:{"disabled":data.productList.length < 1,"variant":"info","size":"sm","block":""}},[_c('span',[_vm._v("Cetak AWB")]),_c('font-awesome-icon',{attrs:{"icon":['fas', 'file-alt']}})],1):_vm._e(),(data.marketplaceType === 'lazada')?_c('b-button',{staticClass:"d-flex justify-content-between align-items-center",attrs:{"disabled":data.productList.length < 1,"variant":"success","size":"sm","block":""}},[_c('span',[_vm._v("Konfirmasi Pengiriman")]),_c('font-awesome-icon',{attrs:{"icon":['fas', 'truck']}})],1):_vm._e()],1)],1)}),1):_c('EmptyTableRow',{attrs:{"colspan":_vm.tableColumns.length + 1}})],1),(_vm.isFetching)?_c('div',{staticClass:"p-2"},[_c('b-skeleton-table',{attrs:{"rows":4,"columns":7,"table-props":{ borderless: true, striped: true, small: true }}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }